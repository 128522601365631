import React from 'react'
import Scroll from 'react-scroll'
import { Helmet } from 'react-helmet'

import Layout from '../../layout/layout'

import '../../scss/sections/cookie-controls.scss'

const Link = Scroll.Link
const Element = Scroll.Element
const Events = Scroll.Events
const scrollSpy = Scroll.scrollSpy

import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

export default class CookiePage extends React.Component {

    static defaultProps = {
        headerAndFooter: true
    }

    async removeCookie() {
        Cookies.remove('gatsby-gdpr-google-tagmanager')
        // Cookies.set('gatsby-gdpr-google-tagmanager', false)
        document.getElementById("cookie_box").checked = false
        await this.readCookie()
    }

    async readCookie() {
        var cookie_status = Cookies.get('gatsby-gdpr-google-tagmanager')
        var cookie_status_label = 'Niet geaccepteerd'
        if (cookie_status == 'true') {
            cookie_status_label = 'Geaccepteerd'
        }
        this.setState({cookie_status: cookie_status, cookie_status_label: cookie_status_label})
    }

    async componentDidUpdate() {
        var cookie_box = document.getElementById("cookie_box")
        if (cookie_box) {
            cookie_box.checked = true
        }
    }

    async componentDidMount() {
        await scrollSpy.update()
        await this.readCookie()
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin')
        Events.scrollEvent.remove('end')
    }

    state = {
        'cookie_status': false,
        'cookie_status_label': 'Niet geaccepteerd',
    }

    render() {
        const { cookie_status, cookie_status_label } = this.state

        return (
            <Layout hasStickyElement {...this.props}>

                <Helmet>
                    <title>Cookies</title>
                </Helmet>

                <section className="text-default">
                    <div className="inner">
                        <div className="text">
                            <h2>Cookiebeleid</h2>
                            <h3>Huidige instellingen</h3>
                            <p>Momenteel heeft u de cookie-instellingen:</p>
                            <div class="cookie-controls">
                                <button className="switch" type="button">
                                    { (cookie_status == 'true') && (
                                      <input id="cookie_box" onClick={() => this.removeCookie()} type="checkbox" />
                                    )}
                                    <label>
                                        <div />
                                    </label>
                                </button>
                                <label>{ cookie_status_label }</label>
                            </div>
                            <h3>1. Definities</h3>
                            <ol>
                                <li>Cookies zijn kleine tekstbestanden die we op uw apparaat plaatsen op het moment dat u onze website bezoekt.</li>
                                <li>We gebruiken cookies om u en uw voorkeuren te herkennen, de prestaties van onze website te verbteren en om analytische informatie te verzamelen voor onszelf en onze zakenpartners. Dit omvat, maar is niet beperkt tot, het analyseren van prestaties van advertenties die we op websites van derden kunnen weergeven.</li>
                                <li>Tevens gebruiken we cookies om u advertenties van Zupr-producten of -diensten op websites van derden te tonen.</li>
                            </ol>
                            <h3>2. Soorten cookies die wij gebruiken</h3>
                            <p>We gebruiken verschillende soorten cookies om u de beste website-ervaring te bieden.</p>
                            <ol>
                                <li><strong>Essenti&euml;le cookies:</strong> zijn vereist voor het functioneren van de website.</li>
                                <li><strong>Functionele cookies:</strong> worden gebruikt om te herkennen wanneer u terugkeert naar de website en om uw voorkeuren te onthouden.</li>
                                <li><strong>Analytics cookies:</strong> helpen ons te begrijpen hoe bezoekers onze website gebruiken, zodat we de ervaring kunnen verbeteren.</li>
                            </ol>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
